import List from 'components/Rental/Results/List';
import { addDays, format } from 'date-fns';
import useRentalInfiniteQuery from 'hooks/useRentalInfiniteQuery';
import useStickyState from 'hooks/useStickyState';
import React, { ReactElement, useState } from 'react';
import { MinMaxRange } from 'types/api';

const Results = (): ReactElement => {
    const periodStart = format(new Date(), 'yyyy-MM-dd');
    const periodEnd = format(addDays(new Date(), 15), 'yyyy-MM-dd');
    const [bedrooms] = useStickyState<number>('bedrooms', 1, true, (value) => parseInt(value, 10));
    const [locations] = useStickyState<Array<string>>('locations', [], true);
    const [highlights] = useStickyState<Array<string>>('highlights', [], true);
    const [prices] = useStickyState<MinMaxRange>(
        'prices',
        {
            min: 1000,
            max: 500000,
        },
        true,
        (value: MinMaxRange) => ({ min: parseInt(value.min.toString(), 10), max: parseInt(value.max.toString(), 10) }),
    );
    const [sort] = useStickyState<string>('sort', 'order[title]=ASC', true);
    const [total, setTotal] = useState(0);
    const params = {
        periodStart,
        periodEnd,
        bedrooms,
        locations,
        highlights,
        prices,
        sort,
        specialOffer: false,
    };

    const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useRentalInfiniteQuery(
        params,
        periodStart,
        periodEnd,
        bedrooms,
        [],
        locations,
        highlights,
        prices,
        sort,
        false,
        (_total) => setTotal(_total),
    );

    return (
        <List
            data={data}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            total={total}
        />
    );
};

export default Results;
