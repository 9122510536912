import GreyContainer from 'components/Layout/GreyContainer';
import H1 from 'components/Layout/H1';
import Results from 'components/LastMinute/Results';
import SearchForm from 'components/LastMinute/SearchForm';
import useSSR from 'hooks/useSSR';
import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Intro } from '../Rentals/styled';

const LastMinute = (): ReactElement => {
    const { t } = useTranslation(['form', 'rental']);
    const { browser } = useSSR();

    const url = '/last-minute';

    return (
        <React.Fragment>
            <Helmet>
                <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}${url}`} />
                <meta property="og:site_name" content={t('rental:list.meta.og.siteName')} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={t('rental:last_minute.meta.og.title')} />
                <meta property="og:description" content={t('rental:last_minute.meta.og.description')} />
                <meta property="og:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta property="twitter:title" content={t('rental:last_minute.meta.twitter.title')} />
                <meta property="twitter:description" content={t('rental:last_minute.meta.twitter.description')} />
                <meta property="twitter:image" content={`${process.env.REACT_APP_SITE_URL}images/logo.png`} />
                <meta name="twitter:card" content="summary" />
                <meta property="title" content={t('rental:last_minute.meta.title')} />
                <title>{t('rental:last_minute.meta.title')}</title>
                <meta property="description" content={t('rental:last_minute.meta.description')} />
            </Helmet>
            <Container className="g-3">
                <Row className="text-center">
                    <Col xs={12}>
                        <H1>
                            <Trans t={t}>rental:last_minute.title</Trans>
                        </H1>
                    </Col>
                    <Col xs={12}>
                        <Intro specialOffer={false}>
                            <Trans t={t} i18nKey="rental:last_minute.intro">
                                <a href="tel:+ 590 (0) 690 47 02 52" />
                            </Trans>
                        </Intro>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SearchForm />
                    </Col>
                </Row>
            </Container>
            <GreyContainer className="flex-grow-1">
                <Container>{browser && <Results />}</Container>
            </GreyContainer>
        </React.Fragment>
    );
};

export default LastMinute;
