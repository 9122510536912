import BedroomSelect from 'components/Form/BedroomSelect';
import DatePickerInput from 'components/Form/DatePickerInput';
import HighlightSelect from 'components/Form/HighlightSelect';
import LocationSelect from 'components/Form/LocationSelect';
import PriceSlider from 'components/Form/PriceSlider';
import { addDays, format } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import useStickyState from 'hooks/useStickyState';
import 'nouislider/distribute/nouislider.css';
import React, { ReactElement, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IoClose, IoSearch } from 'react-icons/io5';
import { MinMaxRange } from 'types/api';
import { CloseSearch, FakeSearch, SearchContainer, SearchTitle } from '../../Rental/SearchForm/styled';

const SearchForm = (): ReactElement => {
    const [showSearch, setShowSearch] = useState(false);
    const periodStart = new Date();
    const periodEnd = addDays(new Date(), 15);
    const [bedrooms, setBedrooms] = useStickyState<number>('bedrooms', 1, false, (value) => parseInt(value, 10));
    const [locations, setLocations] = useStickyState<Array<string>>('locations', []);
    const [highlights, setHighlights] = useStickyState<Array<string>>('highlights', []);
    const [prices, setPrices] = useStickyState<MinMaxRange>(
        'prices',
        {
            min: 1000,
            max: 500000,
        },
        false,
        (value: MinMaxRange) => ({ min: parseInt(value.min.toString(), 10), max: parseInt(value.max.toString(), 10) }),
    );
    const { t, i18n } = useTranslation('form');
    const locale = i18n.language === 'en' ? enUS : fr;

    const form = useForm<RentalSearchFormValues>({
        defaultValues: {
            bedrooms,
            prices,
            locations,
            highlights,
        },
    });

    const { handleSubmit, watch } = form;

    const onSubmit = (values: RentalSearchFormValues): void => {
        setBedrooms(values.bedrooms);
        setLocations(values.locations);
        setHighlights(values.highlights);
        setPrices(values.prices);
    };

    const data = watch();

    useEffect(() => {
        handleSubmit(onSubmit)();
    }, [data]);

    return (
        <React.Fragment>
            <FakeSearch onClick={(): void => setShowSearch(true)} $hidden={showSearch}>
                <IoSearch size={24} />
                {t('rental:list.fake_search')}
            </FakeSearch>
            <SearchContainer $visible={showSearch}>
                <div className="w-100 bg-white pe-2 pe-xl-0">
                    <FormProvider {...form}>
                        <form className="pt-0" onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mx-0">
                                <SearchTitle>
                                    <Row className="g-0">
                                        <Col xs={10} className="py-3 px-4">
                                            Rechercher
                                        </Col>
                                        <CloseSearch onClick={(): void => setShowSearch(false)}>
                                            <IoClose size={32} />
                                        </CloseSearch>
                                    </Row>
                                </SearchTitle>
                                <Col xs={12} lg={4}>
                                    <DatePickerInput
                                        value={`${format(periodStart, 'P', { locale })} - ${format(periodEnd, 'P', {
                                            locale,
                                        })}`}
                                        readonly
                                    />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <BedroomSelect label />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <PriceSlider />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <LocationSelect label={t('form:search.locations')} />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <HighlightSelect label={t('form:search.highlights')} />
                                </Col>
                            </Row>
                        </form>
                    </FormProvider>
                </div>
            </SearchContainer>
        </React.Fragment>
    );
};

export default SearchForm;
